import {
  type EditorSDK,
  type ComponentRef,
  type ComponentClientSpecMapEntry,
  type ComponentDefinition,
} from '@wix/editor-platform-sdk-types';
import { Kind, ListFormsOrder } from '@wix/ambassador-forms-v4-form/types';
import type { FlowAPI, IHttpClient } from '@wix/yoshi-flow-editor';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';
import { NAMESPACE } from '../constants/namespace';
import { createForm, listForms } from '@wix/ambassador-forms-v4-form/http';
import {
  FORM_TEMPLATES,
  FORM_TEMPLATE_NAMES,
  FormAppPreset,
  isEcomRequiredTemplate,
} from '../constants/templates';
import { BLOG_APP_DEF_ID } from '../constants/app';
import { reportAddedWidget } from './bi';
import { installEcomPages } from './ecom';
import type { ErrorHandlerPublicAPI } from '@wix/fe-essentials-editor/error-handler';
import { getRestrictions } from '@wix/ambassador-forms-v4-restrictions/http';

interface GetFormAppComponentsParams {
  appToken: string;
  sdk: EditorSDK;
}

export const getFormAppComponents = async ({
  sdk,
  appToken,
}: GetFormAppComponentsParams) => {
  const { applicationId } = await sdk.document.tpa.app.getDataByAppDefId(
    appToken,
    '225dd912-7dea-4738-8688-4b8c6955ffc2',
  );
  return sdk.tpa.app.getAllCompsByApplicationId(appToken, applicationId);
};

interface SetComponentFormIdParams {
  sdk: EditorSDK;
  appToken: string;
  formId: string;
  compRef: ComponentRef;
}

export const openSettingsPanel = async (
  sdk: EditorSDK,
  appToken: string,
  componentRef: ComponentRef,
) => {
  return setTimeout(
    async () =>
      sdk.editor.openSettingsPanel(appToken, {
        componentRef,
      }),
    3000,
  );
};

interface AddWidgetParams {
  appToken: string;
  sdk: EditorSDK;
  componentRef: ComponentRef;
  component: ComponentDefinition;
  flowApi: FlowAPI;
  originalComponentId?: string;
}

export const addWidgetToSlot = async ({
  appToken,
  sdk,
  componentRef,
  component,
  flowApi,
}: AddWidgetParams) => {
  const { httpClient, bi, errorHandler } = flowApi;
  const presetId = 'blogSlotSubscribe';
  await sdk.components.style.update(appToken, {
    componentRef,
    style: { param_boolean_isInSlot: true },
  });

  const formLimitReached = await isFormsLimitReached({
    httpClient,
    errorHandler,
  });
  if (formLimitReached) {
    return openSettingsPanel(sdk, appToken, componentRef);
  }

  const linguistHeader = await getLinguistHeader(sdk, appToken, flowApi);
  await handlePresetTemplate({
    appToken,
    sdk,
    httpClient,
    errorHandler,
    componentRef,
    presetId,
    linguistHeader,
  });

  const msid = await sdk.info.getMetaSiteId(appToken);
  reportAddedWidget({
    component,
    presetId,
    bi: bi || undefined,
    msid,
  });
};

export const addWidgetToEditor = async ({
  component,
  originalComponentId,
  appToken,
  sdk,
  componentRef,
  flowApi,
}: AddWidgetParams) => {
  const { httpClient, bi, errorHandler } = flowApi;
  const hasFormId = Boolean(getComponentFormId(component));
  if (hasFormId) {
    return;
  }

  const presetId = getComponentPresetId(component);
  const wasCopyPasted = Boolean(originalComponentId);

  if (presetId === FormAppPreset.Blank || presetId === undefined) {
    if (wasCopyPasted) {
      return;
    }
    await handleBlankTemplate({
      appToken,
      sdk,
      httpClient,
      errorHandler,
      componentRef,
      presetId,
    });
  } else if (presetId === FormAppPreset.Existing) {
    if (wasCopyPasted) {
      return;
    }
    await openSettingsPanel(sdk, appToken, componentRef);
  } else {
    if (presetId) {
      const formLimitReached = await isFormsLimitReached({
        httpClient,
        errorHandler,
      });
      if (formLimitReached) {
        return showUpgradeModal(sdk, appToken);
      }

      const linguistHeader = await getLinguistHeader(sdk, appToken, flowApi);
      await Promise.all([
        handlePresetTemplate({
          appToken,
          sdk,
          httpClient,
          errorHandler,
          componentRef,
          presetId,
          linguistHeader,
        }),
        isEcomRequiredTemplate(presetId) && installEcomPages({ flowApi, sdk }),
      ]);
    }
  }

  const msid = await sdk.info.getMetaSiteId(appToken);
  reportAddedWidget({
    component,
    presetId,
    bi: bi || undefined,
    msid,
  });
};

export const getIsInBlogSlot = async (
  appToken: string,
  sdk: EditorSDK,
  component: ComponentDefinition,
) => {
  const parentId = component.parent || '';
  const parentRef = await sdk.document.components.getById(appToken, {
    id: parentId,
  });

  const parent = await sdk.document.components.serialize(appToken, {
    componentRef: parentRef,
  });

  return parent.data?.appDefinitionId === BLOG_APP_DEF_ID;
};

export const setComponentFormId = async ({
  sdk,
  appToken,
  formId,
  compRef,
}: SetComponentFormIdParams) =>
  sdk.tpa.data.set(appToken, {
    compRef,
    scope: 'COMPONENT',
    key: 'formId',
    value: formId,
  });

export const getComponentFormIdFromSpecMapEntry = (
  component: ComponentClientSpecMapEntry,
) => JSON.parse((component as any)?.tpaData?.content ?? null)?.formId;

export const getComponentPresetId = (component: ComponentDefinition) =>
  JSON.parse(component?.data?.tpaData?.content ?? null)?.presetId;

export const getComponentFormId = (component: ComponentDefinition) =>
  JSON.parse(component?.data?.tpaData?.content ?? null)?.formId;

interface HandleTemplateParams {
  sdk: EditorSDK;
  appToken: string;
  httpClient: IHttpClient;
  errorHandler: ErrorHandlerPublicAPI;
  componentRef: ComponentRef;
  presetId?: string;
}

export const handleBlankTemplate = async ({
  appToken,
  sdk,
  httpClient,
  errorHandler,
  componentRef,
  presetId,
}: HandleTemplateParams) => {
  const { withErrorHandler, getResolvedError } = errorHandler;
  try {
    const formLimitReached = await isFormsLimitReached({
      httpClient,
      errorHandler,
    });

    if (formLimitReached) {
      await showUpgradeModal(sdk, appToken);
    } else {
      await sdk.editor.openDashboardPanel(appToken, {
        url: '/wix-forms/form',
        closeOtherPanels: presetId === undefined,
      });
    }

    const response = await withErrorHandler(
      () =>
        httpClient.request(
          listForms({
            namespace: NAMESPACE,
            paging: { limit: 1 },
            order: ListFormsOrder.CREATED_DATE_DESC,
          }),
        ),
      { errorCodesMap: {} },
    );

    if (
      response.data.forms?.[0].createdDate &&
      response.data.forms[0].createdDate.getTime() >
        new Date().getTime() - 200000 &&
      response.data.forms[0].id
    ) {
      await setComponentFormId({
        appToken,
        sdk,
        formId: response.data?.forms?.[0]?.id,
        compRef: componentRef,
      });
    }
  } catch (e) {
    const resolvedError = getResolvedError(e);
    console.error(resolvedError);
  }
};

export const getLinguistHeader = async (
  sdk: EditorSDK,
  appToken: string,
  flowApi: FlowAPI,
) => {
  const language = flowApi.environment.siteLanguage;
  const locale = await sdk.document.info.getSiteRegion(appToken);
  const instance = await sdk.info.getAppInstanceId(appToken);
  return `${language}|${locale}|true|${instance}`;
};

interface HandlePresetTemplateParams extends HandleTemplateParams {
  presetId: string;
  linguistHeader: string;
}

export const handlePresetTemplate = async ({
  httpClient,
  errorHandler,
  sdk,
  appToken,
  presetId,
  componentRef,
  linguistHeader,
}: HandlePresetTemplateParams) => {
  const formLimitReached = await isFormsLimitReached({
    httpClient,
    errorHandler,
  });

  if (formLimitReached) {
    await showUpgradeModal(sdk, appToken);
  } else {
    const formId = (
      await createFormFromTemplate({
        templateId: FORM_TEMPLATES[presetId],
        httpClient,
        errorHandler,
        linguistHeader,
      })
    )?.id;

    formId &&
      (await setComponentFormId({
        appToken,
        sdk,
        formId,
        compRef: componentRef,
      }));

    setTimeout(
      async () =>
        sdk.editor.selection.selectComponentByCompRef(appToken, {
          compsToSelect: [componentRef],
        }),
      5000,
    );
  }
};

export const showUpgradeModal = async (sdk: EditorSDK, appToken: string) => {
  const metaSiteId = await sdk.info.getMetaSiteId(appToken);
  await sdk.editor.openModalPanel(appToken, {
    shouldHideHeader: true,
    url: getPanelUrl('Form', 'UpgradeModal'),
    height: 453,
    width: 600,
    initialData: { metaSiteId },
  });
};

export const isFormsLimitReached = async ({
  httpClient,
  errorHandler,
}: {
  httpClient: IHttpClient;
  errorHandler: ErrorHandlerPublicAPI;
}) => {
  const { withErrorHandler, getResolvedError } = errorHandler;

  try {
    const restrictionsResponse = await withErrorHandler(
      () => httpClient.request(getRestrictions({})).then((res) => res.data),
      { errorCodesMap: {} },
    );
    const formsLimit = restrictionsResponse.restrictions?.formsLimit?.limit;
    const totalActiveFormCount = restrictionsResponse.totalFormCount;

    if (
      formsLimit === undefined ||
      formsLimit === null ||
      totalActiveFormCount === undefined
    ) {
      return false;
    }

    return formsLimit !== -1 && formsLimit <= totalActiveFormCount;
  } catch (e) {
    const resolvedError = getResolvedError(e);
    console.error(resolvedError);
    throw new Error('Failed to fetch restrictions');
  }
};

interface CreateFormFromTemplate {
  httpClient: IHttpClient;
  errorHandler: ErrorHandlerPublicAPI;
  templateId: string;
  linguistHeader: string;
}

export const createFormFromTemplate = async ({
  httpClient,
  errorHandler,
  templateId,
  linguistHeader,
}: CreateFormFromTemplate) => {
  const { withErrorHandler, getResolvedError } = errorHandler;

  try {
    const formToCreate = await withErrorHandler(
      () =>
        httpClient.request((args) => ({
          ...listForms({
            formIds: [templateId],
            namespace: NAMESPACE,
            kind: Kind.EXTENSION,
          })(args),
          headers: { 'x-wix-linguist': linguistHeader },
        })),
      { errorCodesMap: {} },
    );

    const response = await withErrorHandler(
      () =>
        httpClient.request(
          createForm({
            form: {
              ...formToCreate.data.forms?.[0],
              properties: {
                name: FORM_TEMPLATE_NAMES[templateId],
                disabled: false,
              },
              kind: Kind.REGULAR,
            },
          }),
        ),
      {
        errorCodesMap: {
          applicationError: {
            DUPLICATED_FIELD_TARGETS: false,
            DUPLICATED_FIELD_IDS: false,
            DUPLICATED_STEP_IDS: false,
            DUPLICATED_RULE_IDS: false,
            MISSING_FIELD_TARGETS: false,
            UNSUPPORTED_FIELD_TARGETS_NAME: false,
            MISSING_NESTED_FORM_IDS: false,
            NON_EXISTING_NESTED_FORMS: false,
            NESTED_FORM_FIELDS_MISSING: false,
            FIELD_SCHEMA_NOT_SUPPORTED: false,
            UNSUPPORTED_FORM_NAMESPACE: false,
            FORM_FIELDS_COUNT_EXCEEDED: false,
            NAMESPACE_FORMS_COUNT_EXCEEDED: false,
            NAMESPACE_DELETED_FORMS_COUNT_EXCEEDED: false,
            FORM_SIZE_EXCEEDED: false,
            DUPLICATED_PRODUCT_IDS: false,
            MISSING_PRODUCT_NAME: false,
          },
        },
      },
    );

    return response.data.form;
  } catch (e) {
    const resolvedError = getResolvedError(e);
    console.error(resolvedError);
  }
};
